<template>
  <v-dialog
    v-model="deviceDetailDialog"
    max-width="1840"
    content-class="device_detail_dialog"
    persistent
  >
    <v-card id="device_detail_wp" class="dialog_detail_content">
      <v-card-title>
        <div class="close_button" title="Close" @click="closeDialog">
          <span>
            <i class="fas fa-times"></i>
          </span>
        </div>
        <button
          class="dialog-default-button dialog-button-add-size dialog-bg-grey-button"
          style="opacity: 1; cursor: default;"
          :ripple="false"
          type="button"
        >
          DETAIL
        </button>
        <button
          class="ml-4 dialog-default-button dialog-button-add-size dialog-bg-outline-button"
          :ripple="false"
          type="button"
          @click="openDialogHistoryRecord"
        >
          HISTORY RECORD
        </button>
      </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="4" class="basic_info_col">
            <div v-if="deviceDetailData" class="basic_info_wp">
              <div class="status_image">
                <div class="device_status">
                  <p :style="`color: ${STATUS_COLORS[deviceDetailData.device?.status]}`">{{ deviceDetailData.device?.status ? capitalizeFirstLetter(deviceDetailData.device.status) : '' }}</p>
                </div>
                <div class="device_image">
                  <img :src="deviceDetailData.device?.image ? deviceDetailData.device?.image : ''" />
                </div>
              </div>
              <div class="information_card">
                <div class="information_card_header">
                  <div class="row_information_space">
                    <h2 class="information_title">Basic Info</h2>
                    <!-- <div class="device_detail_actions">
                      <v-btn 
                        class="information_action_btn" 
                        icon
                      >
                        <img src="@/assets/tables/asset_edit.svg" />
                      </v-btn>
                    </div> -->
                  </div>
                </div>
                <div class="information_card_content">
                  <div class="row_information_space">
                    <p class="detail_title">Device Name</p>
                    <p class="detail_content">{{ deviceDetailData.device?.name ? deviceDetailData.device.name : '−−' }}</p>
                  </div>
                  <div class="row_information_space">
                    <p class="detail_title">SN</p>
                    <p class="detail_content">{{ deviceDetailData.device?.sn ? deviceDetailData.device.sn : '−−' }}</p>
                  </div>
                  <div class="row_information_space">
                    <p class="detail_title">Brand</p>
                    <p class="detail_content">{{ deviceDetailData.device?.brand ? deviceDetailData.device.brand : '−−' }}</p>
                  </div>
                  <div class="row_information_space">
                    <p class="detail_title">Hardware Model</p>
                    <p class="detail_content">{{ deviceDetailData.device?.model ? deviceDetailData.device.model : '−−' }}</p>
                  </div>
                  <div class="row_information_space">
                    <p class="detail_title">Software Version</p>
                    <p class="detail_content">{{ deviceDetailData.device?.software_version ? deviceDetailData.device.software_version : '✕' }}</p>
                  </div>
                  <div class="row_information_space">
                    <p class="detail_title">Rated Value</p>
                    <p class="detail_content">{{ deviceDetailData.device?.rated_capacity ? deviceDetailData.device.rated_capacity : '−−' }}</p>
                  </div>
                  <div class="row_information_space">
                    <p class="detail_title">Commissioned Date</p>
                    <p class="detail_content">{{ deviceDetailData.device?.date_of_commission ? MOMENT(deviceDetailData.device.date_of_commission, "DD/MM/YYYY") : '−−' }}</p>
                  </div>
                  <div class="row_information_space">
                    <p class="detail_title">Last Maintenance</p>
                    <p class="detail_content">{{ deviceDetailData.device?.last_maintenance ? MOMENT(deviceDetailData.device.last_maintenance, "DD/MM/YYYY") : '−−' }}</p>
                  </div>
                  <div class="row_information_space">
                    <p class="detail_title">Next Maintenance</p>
                    <p class="detail_content">{{ deviceDetailData.device?.next_maintenance ? MOMENT(deviceDetailData.device.next_maintenance, "DD/MM/YYYY") : '(Not scheduled)' }}</p>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="8" class="more_info_col">
            <div class="more_info_wp">
              <!-- Device EV Charger (evse) -->
              <template v-if="device_type === 'evse'">
                <div class="more_info_tabs">
                  <v-btn-toggle v-model="selectedInfoTabs" @change="changeInfoTabs" mandatory>
                    <v-btn value="connector_1">Connector 1</v-btn>
                    <v-btn value="connector_2">Connector 2</v-btn>
                  </v-btn-toggle>
                </div>
                <div class="more_info_content">
                  <div class="operation_realtime_info">
                    <div class="more_info_heading">
                      <h2 class="more_info_title">Operation & Realtime Info</h2>
                    </div>
                    <div class="more_info_desc">
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Charging State</h3>
                            <p>{{ moreDetailInfoData?.charge_status ? capitalizeFirstLetter(moreDetailInfoData.charge_status) : '−−'  }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Charging Duration</h3>
                            <p>{{ moreDetailInfoData?.charging_time ? formatFullTime(moreDetailInfoData.charging_time) : '−−' }} seconds</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Charging Power</h3>
                            <p>{{ moreDetailInfoData?.power !== null && moreDetailInfoData?.power !== undefined ? formatDecimalNumber(moreDetailInfoData.power) : '−−' }} kW</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Charging Energy</h3>
                            <p>{{ moreDetailInfoData?.energy !== null && moreDetailInfoData?.energy !== undefined ? formatDecimalNumber(moreDetailInfoData.energy) : '−−' }} kWh</p>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Voltage</h3>
                            <p>{{ moreDetailInfoData?.voltage !== null && moreDetailInfoData?.voltage !== undefined ? formatDecimalNumber(moreDetailInfoData.voltage) : '−−' }} V</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Current</h3>
                            <p>{{ moreDetailInfoData?.current !== null && moreDetailInfoData?.current !== undefined ? formatDecimalNumber(moreDetailInfoData.current) : '−−' }} A</p>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Next Schedule Start at</h3>
                            <p>{{ moreDetailInfoData?.start_time ? MOMENT(moreDetailInfoData.start_time, "DD/MM/YYYY HH:mm:ss") : '−−' }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Next Schedule End at</h3>
                            <p>{{ moreDetailInfoData?.end_time ? MOMENT(moreDetailInfoData.end_time, "DD/MM/YYYY HH:mm:ss") : '−−' }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Next Schedule Setting</h3>
                            <p>{{ moreDetailInfoData?.reserve_current !== null && moreDetailInfoData?.reserve_current !== undefined ? formatDecimalNumber(moreDetailInfoData.reserve_current) : '−−' }} A</p>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                  <div class="configuration_info">
                    <div class="more_info_heading">
                      <h2 class="more_info_title">Configuration Info</h2>
                    </div>
                    <div class="more_info_desc">
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>EVSE Phase</h3>
                            <p>{{ moreDetailInfoData?.evse_phase ? evsePhaseText[moreDetailInfoData.evse_phase] : '−−' }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Is Locker</h3>
                            <p>{{ moreDetailInfoData?.is_lock ? 'True' : 'False' }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Lock Status</h3>
                            <p>{{ moreDetailInfoData?.lock_status ? 'Locked' : 'Unlocked' }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Plug-and-Charge Status</h3>
                            <p>{{ moreDetailInfoData?.pnc_status ? 'Enabled' : 'Disabled' }}</p>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Load Balance</h3>
                            <p>{{ moreDetailInfoData?.load_balance !== null && moreDetailInfoData?.load_balance !== undefined ? formatDecimalNumber(moreDetailInfoData.load_balance) : '−−' }} W</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Minimum Current</h3>
                            <p>{{ moreDetailInfoData?.min_current !== null && moreDetailInfoData?.min_current !== undefined ? formatDecimalNumber(moreDetailInfoData.min_current) : '−−' }} A</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Maximum Current</h3>
                            <p>{{ moreDetailInfoData?.max_current !== null && moreDetailInfoData?.max_current !== undefined ? formatDecimalNumber(moreDetailInfoData.max_current) : '−−' }} A</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>User Setting Current</h3>
                            <p>{{ moreDetailInfoData?.user_current !== null && moreDetailInfoData?.user_current !== undefined ? formatDecimalNumber(moreDetailInfoData.user_current) : '−−' }} A</p>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </div>
              </template>
              <!-- End Device EV Charger (evse) -->

              <!-- Device PV Inverter (pv) -->
              <template v-if="device_type === 'pv'">
                <div class="more_info_tabs">
                  <v-btn-toggle v-model="selectedInfoTabs" @change="changeInfoTabs" mandatory>
                    <v-btn value="pv_string_1">PV String 1</v-btn>
                    <v-btn value="pv_string_2">PV String 2</v-btn>
                    <v-btn value="pv_string_3">PV String 3</v-btn>
                  </v-btn-toggle>
                </div>
                <div class="more_info_content">
                  <div class="operation_realtime_info">
                    <div class="more_info_heading">
                      <h2 class="more_info_title">Operation & Realtime Info</h2>
                    </div>
                    <div class="more_info_desc">
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Inverter Status</h3>
                            <p>{{ moreDetailInfoData?.inverter_status !== null && moreDetailInfoData?.inverter_status !== undefined ? inverterStatusText[moreDetailInfoData.inverter_status] : '−−' }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Active Power</h3>
                            <p>{{ moreDetailInfoData?.active_power !== null && moreDetailInfoData?.active_power !== undefined ? formatDecimalNumber(moreDetailInfoData.active_power) : '−−' }} kW</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Daily Energy</h3>
                            <p>{{ moreDetailInfoData?.daily_active_energy !== null && moreDetailInfoData?.daily_active_energy !== undefined ? formatDecimalNumber(moreDetailInfoData.daily_active_energy) : '−−' }} kWh</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Cumulative Energy</h3>
                            <p>{{ moreDetailInfoData?.active_energy !== null && moreDetailInfoData?.active_energy !== undefined ? formatDecimalNumber(moreDetailInfoData.active_energy) : '−−' }} kWh</p>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Reactive Power</h3>
                            <p>{{ moreDetailInfoData?.reactive_power !== null && moreDetailInfoData?.reactive_power !== undefined ? formatDecimalNumber(moreDetailInfoData.reactive_power) : '−−' }} kVAr</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Power Factor</h3>
                            <p>−−</p>
                            <!-- <p>{{ moreDetailInfoData?.power_factor !== null && moreDetailInfoData?.power_factor !== undefined ? moreDetailInfoData.power_factor : '−−' }}</p> -->
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Internal Temperature</h3>
                            <p>−−°C</p>
                            <!-- <p>{{ moreDetailInfoData?.internal_temperature !== null && moreDetailInfoData?.internal_temperature !== undefined ? moreDetailInfoData.internal_temperature : '−−' }}°C</p> -->
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Grid Frequency</h3>
                            <p>−− Hz</p>
                            <!-- <p>{{ moreDetailInfoData?.grid_frequency !== null && moreDetailInfoData?.grid_frequency !== undefined ? formatDecimalNumber(moreDetailInfoData.grid_frequency) : '−−' }} Hz</p> -->
                          </div>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Phase A Voltage</h3>
                            <p>−− V</p>
                            <!-- <p>{{ moreDetailInfoData?.phase_a_voltage !== null && moreDetailInfoData?.phase_a_voltage !== undefined ? formatDecimalNumber(moreDetailInfoData.phase_a_voltage) : '−−' }} V</p> -->
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Phase B Voltage</h3>
                            <p>−− V</p>
                            <!-- <p>{{ moreDetailInfoData?.phase_b_voltage !== null && moreDetailInfoData?.phase_b_voltage !== undefined ? formatDecimalNumber(moreDetailInfoData.phase_b_voltage) : '−−' }} V</p> -->
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Phase C Voltage</h3>
                            <p>−− V</p>
                            <!-- <p>{{ moreDetailInfoData?.phase_c_voltage !== null && moreDetailInfoData?.phase_c_voltage !== undefined ? formatDecimalNumber(moreDetailInfoData.phase_c_voltage) : '−−' }} V</p> -->
                          </div>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Phase A Current</h3>
                            <p>−− A</p>
                            <!-- <p>{{ moreDetailInfoData?.phase_a_current !== null && moreDetailInfoData?.phase_a_current !== undefined ? formatDecimalNumber(moreDetailInfoData.phase_a_current) : '−−' }} A</p> -->
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Phase B Current</h3>
                            <p>−− A</p>
                            <!-- <p>{{ moreDetailInfoData?.phase_b_current !== null && moreDetailInfoData?.phase_b_current !== undefined ? formatDecimalNumber(moreDetailInfoData.phase_b_current) : '−−' }} A</p> -->
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Phase C Current</h3>
                            <p>−− A</p>
                            <!-- <p>{{ moreDetailInfoData?.phase_c_current !== null && moreDetailInfoData?.phase_c_current !== undefined ? formatDecimalNumber(moreDetailInfoData.phase_c_current) : '−−' }} A</p> -->
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                  <div class="configuration_info">
                    <div class="more_info_heading">
                      <h2 class="more_info_title">Configuration Info</h2>
                    </div>
                    <div class="more_info_desc">
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Operation</h3>
                            <p>−−</p>
                            <!-- <p>{{ moreDetailInfoData?.operation ? moreDetailInfoData.operation : '−−' }}</p> -->
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>MPPT Mode</h3>
                            <p>−−</p>
                            <!-- <p>{{ moreDetailInfoData?.mppt_mode ? moreDetailInfoData.mppt_mode : '−−' }}</p> -->
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Anti-backflow Function</h3>
                            <p>−−</p>
                            <!-- <p>{{ moreDetailInfoData?.anti_backflow_function ? 'Enabled' : 'Disabled' }}</p> -->
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Active Power Limit</h3>
                            <p>−− kW</p>
                            <!-- <p>{{ moreDetailInfoData?.active_power_limit !== null && moreDetailInfoData?.active_power_limit !== undefined ? formatDecimalNumber(moreDetailInfoData.active_power_limit) : '−−' }} kW</p> -->
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </div>
              </template>
              <!-- End Device PV Inverter (pv) -->

              <!-- Device Battery (bess) -->
              <template v-if="device_type === 'bess'">
                <div class="more_info_tabs">
                  <v-btn-toggle v-model="selectedInfoTabs" @change="changeInfoTabs" mandatory>
                    <v-btn value="pack_1">Pack 1</v-btn>
                    <v-btn value="pack_2">Pack 2</v-btn>
                  </v-btn-toggle>
                </div>
                <div class="more_info_content">
                  <div class="operation_realtime_info">
                    <div class="more_info_heading">
                      <h2 class="more_info_title">Operation & Realtime Info</h2>
                    </div>
                    <div class="more_info_desc">
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Battery Status</h3>
                            <p>{{ moreDetailInfoData?.battery_status ? capitalizeFirstLetter(moreDetailInfoData.battery_status) : '−−'  }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Output Power</h3>
                            <p>{{ moreDetailInfoData?.active_power !== null && moreDetailInfoData?.active_power !== undefined ? formatDecimalNumber(moreDetailInfoData.active_power) : '−−' }} kW</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Bus Voltage</h3>
                            <p>{{ moreDetailInfoData?.voltage !== null && moreDetailInfoData?.voltage !== undefined ? formatDecimalNumber(moreDetailInfoData.voltage) : '−−' }} V</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Bus Current</h3>
                            <p>{{ moreDetailInfoData?.current !== null && moreDetailInfoData?.current !== undefined ? formatDecimalNumber(moreDetailInfoData.current) : '−−' }} A</p>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>State-of-Charge</h3>
                            <p>{{ moreDetailInfoData?.soc_percent !== null && moreDetailInfoData?.soc_percent !== undefined ? moreDetailInfoData.soc_percent : '−−' }} %</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>State-of-Health</h3>
                            <p>{{ moreDetailInfoData?.soh_percent !== null && moreDetailInfoData?.soh_percent !== undefined ? moreDetailInfoData.soh_percent : '−−' }} %</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Internal Temperature</h3>
                            <p>{{ moreDetailInfoData?.temperature !== null && moreDetailInfoData?.temperature !== undefined ? moreDetailInfoData.temperature : '−−' }}°C</p>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Daily Charge Energy</h3>
                            <p>{{ moreDetailInfoData?.daily_charged_energy !== null && moreDetailInfoData?.daily_charged_energy !== undefined ? formatDecimalNumber(moreDetailInfoData.daily_charged_energy) : '−−' }} kWh</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Total Charge Energy</h3>
                            <p>{{ moreDetailInfoData?.charged_energy !== null && moreDetailInfoData?.charged_energy !== undefined ? formatDecimalNumber(moreDetailInfoData.charged_energy) : '−−' }} kWh</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Daily Discharge Energy</h3>
                            <p>{{ moreDetailInfoData?.daily_discharged_energy !== null && moreDetailInfoData?.daily_discharged_energy !== undefined ? formatDecimalNumber(moreDetailInfoData.daily_discharged_energy) : '−−' }} kWh</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Total Discharge Energy</h3>
                            <p>{{ moreDetailInfoData?.discharged_energy !== null && moreDetailInfoData?.discharged_energy !== undefined ? formatDecimalNumber(moreDetailInfoData.discharged_energy) : '−−' }} kWh</p>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                  <div class="configuration_info">
                    <div class="more_info_heading">
                      <h2 class="more_info_title">Configuration Info</h2>
                    </div>
                    <div class="more_info_desc">
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Operation Mode</h3>
                            <p>−−</p>
                            <!-- <p>{{ moreDetailInfoData?.operation_mode ? moreDetailInfoData.operation_mode : '−−' }}</p> -->
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Protection Mode</h3>
                            <p>−−</p>
                            <!-- <p>{{ moreDetailInfoData?.protection_mode ? 'Enabled' : 'Disabled' }}</p> -->
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Minimum SOC Limit</h3>
                            <p>−− %</p>
                            <!-- <p>{{ moreDetailInfoData?.minimum_soc_limit !== null && moreDetailInfoData?.minimum_soc_limit !== undefined ? moreDetailInfoData.minimum_soc_limit : '−−' }} %</p> -->
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Maximum SOC Limit</h3>
                            <p>−− %</p>
                            <!-- <p>{{ moreDetailInfoData?.maximum_soc_limit !== null && moreDetailInfoData?.maximum_soc_limit !== undefined ? moreDetailInfoData.maximum_soc_limit : '−−' }} %</p> -->
                          </div>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Charge Voltage Limit</h3>
                            <p>−− V</p>
                            <!-- <p>{{ moreDetailInfoData?.charge_voltage_limit !== null && moreDetailInfoData?.charge_voltage_limit !== undefined ? formatDecimalNumber(moreDetailInfoData.charge_voltage_limit) : '−−' }} V</p> -->
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Charge Current Limit</h3>
                            <p>−− A</p>
                            <!-- <p>{{ moreDetailInfoData?.charge_current_limit !== null && moreDetailInfoData?.charge_current_limit !== undefined ? formatDecimalNumber(moreDetailInfoData.charge_current_limit) : '−−' }} A</p> -->
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Discharge Voltage Limit</h3>
                            <p>−− V</p>
                            <!-- <p>{{ moreDetailInfoData?.discharge_voltage_limit !== null && moreDetailInfoData?.discharge_voltage_limit !== undefined ? formatDecimalNumber(moreDetailInfoData.discharge_voltage_limit) : '−−' }} V</p> -->
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Discharge Current Limit</h3>
                            <p>−− A</p>
                            <!-- <p>{{ moreDetailInfoData?.discharge_current_limit !== null && moreDetailInfoData?.discharge_current_limit !== undefined ? formatDecimalNumber(moreDetailInfoData.discharge_current_limit) : '−−' }} A</p> -->
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </div>
              </template>
              <!-- End Device Battery (bess) -->

              <!-- Device Smart meter (pm) -->
              <template v-if="device_type === 'pm'">
                <div class="more_info_tabs" style="visibility: hidden;">
                  <v-btn-toggle @change="changeInfoTabs" mandatory>
                    <v-btn>PM 1</v-btn>
                    <v-btn>PM 2</v-btn>
                  </v-btn-toggle>
                </div>
                <div class="more_info_content">
                  <div class="operation_realtime_info">
                    <div class="more_info_heading">
                      <h2 class="more_info_title">Operation & Realtime Info</h2>
                    </div>
                    <div class="more_info_desc">
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Active Power</h3>
                            <p>{{ moreDetailInfoData?.active_power !== null && moreDetailInfoData?.active_power !== undefined ? formatDecimalNumber(moreDetailInfoData.active_power) : '−−' }} kW</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Reactive Power</h3>
                            <p>−− kW</p>
                            <!-- <p>{{ moreDetailInfoData?.reactive_power !== null && moreDetailInfoData?.reactive_power !== undefined ? formatDecimalNumber(moreDetailInfoData.reactive_power) : '−−' }} kW</p> -->
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Power Factor</h3>
                            <p>−−</p>
                            <!-- <p>{{ moreDetailInfoData?.power_factor !== null && moreDetailInfoData?.power_factor !== undefined ? moreDetailInfoData.power_factor : '−−' }}</p> -->
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Grid Frequency</h3>
                            <p>{{ moreDetailInfoData?.grid_frequency !== null && moreDetailInfoData?.grid_frequency !== undefined ? formatDecimalNumber(moreDetailInfoData.grid_frequency) : '−−' }} Hz</p>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Import Energy</h3>
                            <p>{{ moreDetailInfoData?.import_energy !== null && moreDetailInfoData?.import_energy !== undefined ? formatDecimalNumber(moreDetailInfoData.import_energy) : '−−' }} kWh</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Export Energy</h3>
                            <p>{{ moreDetailInfoData?.export_energy !== null && moreDetailInfoData?.export_energy !== undefined ? formatDecimalNumber(moreDetailInfoData.export_energy) : '−−' }} kWh</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Active Energy</h3>
                            <p>{{ moreDetailInfoData?.active_energy !== null && moreDetailInfoData?.active_energy !== undefined ? formatDecimalNumber(moreDetailInfoData.active_energy) : '−−' }} kWh</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Net Energy</h3>
                            <p>{{ moreDetailInfoData?.net_energy !== null && moreDetailInfoData?.net_energy !== undefined ? formatDecimalNumber(moreDetailInfoData.net_energy) : '−−' }} kWh</p>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Phase A Voltage</h3>
                            <p>−− V</p>
                            <!-- <p>{{ moreDetailInfoData?.phase_a_voltage !== null && moreDetailInfoData?.phase_a_voltage !== undefined ? formatDecimalNumber(moreDetailInfoData.phase_a_voltage) : '−−' }} V</p> -->
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Phase B Voltage</h3>
                            <p>−− V</p>
                            <!-- <p>{{ moreDetailInfoData?.phase_b_voltage !== null && moreDetailInfoData?.phase_b_voltage !== undefined ? formatDecimalNumber(moreDetailInfoData.phase_b_voltage) : '−−' }} V</p> -->
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Phase C Voltage</h3>
                            <p>−− V</p>
                            <!-- <p>{{ moreDetailInfoData?.phase_c_voltage !== null && moreDetailInfoData?.phase_c_voltage !== undefined ? formatDecimalNumber(moreDetailInfoData.phase_c_voltage) : '−−' }} V</p> -->
                          </div>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Phase A Current</h3>
                            <p>−− A</p>
                            <!-- <p>{{ moreDetailInfoData?.phase_a_current !== null && moreDetailInfoData?.phase_a_current !== undefined ? formatDecimalNumber(moreDetailInfoData.phase_a_current) : '−−' }} A</p> -->
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Phase B Current</h3>
                            <p>−− A</p>
                            <!-- <p>{{ moreDetailInfoData?.phase_b_current !== null && moreDetailInfoData?.phase_b_current !== undefined ? formatDecimalNumber(moreDetailInfoData.phase_b_current) : '−−' }} A</p> -->
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Phase C Current</h3>
                            <p>−− A</p>
                            <!-- <p>{{ moreDetailInfoData?.phase_c_current !== null && moreDetailInfoData?.phase_c_current !== undefined ? formatDecimalNumber(moreDetailInfoData.phase_c_current) : '−−' }} A</p> -->
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                  <div class="configuration_info">
                    <div class="more_info_heading">
                      <h2 class="more_info_title">Configuration Info</h2>
                    </div>
                    <div class="more_info_desc">
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>System Type</h3>
                            <p>−−</p>
                            <!-- <p>{{ moreDetailInfoData?.system_type ? moreDetailInfoData.system_type : '−−' }}</p> -->
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </div>
              </template>
              <!-- End Device Smart meter (pm) -->

              <!-- Device eBox (sc) -->
              <template v-if="device_type === 'sc'">
                <div class="more_info_tabs" style="visibility: hidden;">
                  <v-btn-toggle @change="changeInfoTabs" mandatory>
                    <v-btn>SC 1</v-btn>
                    <v-btn>SC 2</v-btn>
                  </v-btn-toggle>
                </div>
                <div class="more_info_content">
                  <div class="operation_realtime_info">
                    <div class="more_info_heading">
                      <h2 class="more_info_title">Operation & Realtime Info</h2>
                    </div>
                    <div class="more_info_desc">
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Current Status</h3>
                            <p>{{ moreDetailInfoData?.status ? capitalizeFirstLetter(moreDetailInfoData.status) : '−−' }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Operation Mode</h3>
                            <p>−−</p>
                            <!-- <p>{{ moreDetailInfoData?.operation_mode ? moreDetailInfoData.operation_mode : '−−' }}</p> -->
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                  <div class="configuration_info">
                    <div class="more_info_heading">
                      <h2 class="more_info_title">Configuration Info</h2>
                    </div>
                    <div class="more_info_desc">
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Internet Connection</h3>
                            <p>−−</p>
                            <!-- <p>{{ moreDetailInfoData?.internet_connection ? moreDetailInfoData.internet_connection : '−−' }}</p> -->
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Connection Status</h3>
                            <p>−−</p>
                            <!-- <p>{{ moreDetailInfoData?.system_type ? moreDetailInfoData.system_type : '−−' }}</p> -->
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                  <!-- <div class="configuration_info">
                    <div class="more_info_heading">
                      <h2 class="more_info_title">Firmware Update</h2>
                    </div>
                    <div class="more_info_desc">
                      <v-row no-gutters class="more_info_desc_row">
                      </v-row>
                    </div>
                  </div> -->
                </div>
              </template>
              <!-- End Device eBox (sc) -->
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="dialogHistoryRecord"
      persistent
      content-class="custom-content-modals custom-history-record"
      max-width="500"
    >
      <v-card>
        <v-card-title>History Record</v-card-title>
        <v-card-text class="custom-asset-card v-card__text-history">
          <v-form
            ref="historyRecordForm"
            @submit.prevent="submitHistoryRecord"
            autocomplete="off"
          >
            <v-row no-gutters class="pt-2">
              <v-col cols="12" md="5" class="modal-label">
                <span>Select Report Range</span>
              </v-col>
              <v-col cols="12" md="7">
                <v-select
                  :items="reportRangeTypeList"
                  v-model="reportRange"
                  :rules="reportRangeRules"
                  item-text="name"
                  item-value="key"
                  :class="`${reportRange ? 'custom-hidden-input' : ''}`"
                  required
                  filled
                  append-icon="fas fa-caret-down"
                  placeholder="Select Report Range"
                  :validate-on-blur="true"
                  @change="handleChangeRangeType"
                ></v-select>
              </v-col>
            </v-row>
            <v-row no-gutters class="pt-2 checkboxes-row text-light">
              <v-col cols="6" md="6" class="date-picker-table">
                <v-row no-gutters class="date-picker-row">
                  <p class="date-picker-title">From</p>
                  <v-menu
                    ref="historyRecordDateMenu"
                    v-model="historyRecordDateMenu"
                    :close-on-content-click="false"
                    :disabled="!['custom_range'].includes(reportRange)"
                    transition="scale-transition"
                    offset-y
                    max-width="332"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="historyRecordDateTextFrom"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        append-icon="fas fa-caret-down"
                        class="date-picker-text-field"
                        :class="`${!['custom_range'].includes(reportRange) ? 'disabled_input_field' : '' }`"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      flat
                      v-model="historyRecordDateRange"
                      no-title
                      range
                      width="auto"
                      first-day-of-week="0"
                      type="date"
                      :dark="true"
                      class="customize_commissioned_date_picker"
                      :disabled="!['custom_range'].includes(reportRange)"
                      @input="closeHistoryRecordDateMenu"
                    ></v-date-picker>
                  </v-menu>
                </v-row>
              </v-col>
              <v-col cols="6" md="6" class="date-picker-table">
                <v-row no-gutters class="date-picker-row">
                  <p class="date-picker-title">To</p>
                  <v-text-field
                    v-model="historyRecordDateTextTo"
                    readonly
                    append-icon="fas fa-caret-down"
                    class="date-picker-text-field"
                    :class="`${!['custom_range'].includes(reportRange) ? 'disabled_input_field' : '' }`"
                    @click="openHistoryRecordDateMenu"
                  >
                  </v-text-field>
                </v-row>
              </v-col>
            </v-row>
            <v-row no-gutters class="pt-2 history-button-space">
              <v-spacer></v-spacer>
              <v-col cols="12" class="text-center">
                <button
                  class="dialog-default-button dialog-button-add-size dialog-bg-outline-button"
                  :ripple="false"
                  type="button"
                  @click="closeDialogHistoryRecord"
                >
                  CANCEL
                </button>
                <button
                  class="ml-4 dialog-default-button dialog-button-add-size dialog-bg-grey-button"
                  :ripple="false"
                  type="submit"
                >
                  DOWNLOAD
                </button>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="messageDialog"
      max-width="500"
      content-class="confirm-dialog-box"
    >
      <v-card>
        <v-card-title class="justify-content-center">{{ apiTitle }}</v-card-title>
        <v-card-text
          ><v-row no-gutters>
            <v-col cols="12" class="text-center">
              <span v-html="apiMessage"></span>
            </v-col>
            <v-col cols="12" class="dialog-buttons-padding text-center">
              <button
                class="dialog-default-button dialog-button-confirm-size dialog-bg-grey-button"
                type="button"
                :ripple="false"
                @click="closeMessage"
              >
                OK
              </button>
            </v-col>
          </v-row></v-card-text
        >
      </v-card>
    </v-dialog>
  </v-dialog>
</template>
<style lang="scss" src="@/assets/css/device-detail.scss"></style>
<script>
import { assetService } from "@/services";
import moment from "moment";

import ev_charger from "@/assets/pages/device/ev_charger.png";
import pv_inverter from "@/assets/pages/device/pv_inverter.png";
import bess from "@/assets/pages/device/bess.png";
import smart_meter from "@/assets/pages/device/smart_meter.png";
import eBox from "@/assets/pages/device/eBox.png";

export default {
  props: {
    deviceDetailDialog: {
      default: false
    },
    dataDevice: {
      default: null
    },
  },
  async created() {
    await this.openDialogDeviceDetail(this.dataDevice.id);
  },
  computed: {
    historyRecordDateTextFrom: {
      get() {
        if (this.historyRecordDateRange && this.historyRecordDateRange.length > 0) {
          return `${this.historyRecordDateRange[0] ? moment(this.historyRecordDateRange[0]).format("DD/MM/YYYY") : ''}`;
        }
        return null;
      },
      set() {}
    },
    historyRecordDateTextTo: {
      get() {
        if (this.historyRecordDateRange && this.historyRecordDateRange.length > 1) {
          return `${this.historyRecordDateRange[1] ? moment(this.historyRecordDateRange[1]).format("DD/MM/YYYY") : ''}`;
        }
        return null;
      },
      set() {}
    },
  },
  data() {
    return {
      dialogHistoryRecord: false,
      reportRange: "",
      reportRangeText: "",
      historyRecordDateMenu: false,
      historyRecordDateRange: [],
      reportRangeTypeList: [
        {
          key: "all",
          name: "All",
        },
        {
          key: "month_to_date",
          name: "Month To Date",
        },
        {
          key: "year_to_date",
          name: "Year to Date",
        },
        {
          key: "previous_month",
          name: "Previous Month",
        },
        {
          key: "previous_year",
          name: "Previous Year",
        },
        {
          key: "past_30_days",
          name: "Past 30 Days",
        },
        {
          key: "custom_range",
          name: "Custom Range",
        },
      ],
      reportRangeRules: [
        (v) =>!!v || "Report Range is required",
      ],
      selectedInfoTabs: "",
      deviceDetailData: {},
      moreDetailInfoData: null,
      devicesImageUrl: {
        "sc": eBox,
        "pv": pv_inverter,
        "bess": bess,
        "evse": ev_charger,
        "pm": smart_meter,
      },
      evsePhaseText: {
        "singlephase": "Single-phase",
        "threephase": "Three-phase",
      },
      inverterStatusText: {
        0: "Power-On",
        1: "Self-Test",
        2: "Off-Grid",
        3: "Grid-Tie",
        4: "By-Pass",
        5: "Stop",
      },
      messageDialog: false,
      apiTitle: "",
      apiMessage: "",
      socketData: null,
      deviceTypeTab: {
        "sc": "sc",
        "pv": "pv1",
        "bess": "pack1",
        "evse": "connector1",
        "pm": "pm",
      },
      device_type: null,
    };
  },
  mounted() {
    this.socketData = new WebSocket(this.WEB_SOCKET_URL);
    this.socketData.onmessage = (event) => {
      const socketEventData = JSON.parse(event.data);
      if(socketEventData.action === "get_info_device_detail" && socketEventData.data.asset_id == this.dataDevice.id) {
        const deviceType = this.device_type;
        const deviceDetail = this.deviceDetailData.device[this.deviceTypeTab[deviceType]];
        let dataToMerge = { ...socketEventData.data };
        if (dataToMerge?.daily_active_energy) {
          delete dataToMerge.daily_active_energy;
        }
        this.deviceDetailData.device[this.deviceTypeTab[deviceType]] = { ...deviceDetail, ...dataToMerge };
        this.changeInfoTabs();
      }
    };
  },
  methods: {
    closeDialog () {
      this.$emit('closeDialog');
    },
    openDialogHistoryRecord() {
      this.dialogHistoryRecord = true;
    },
    revertHistoryRecord() {
      this.$refs.historyRecordForm.reset();
    },
    closeDialogHistoryRecord() {
      this.revertHistoryRecord();
      this.historyRecordDateRange = [];
      this.dialogHistoryRecord = false;
    },
    openHistoryRecordDateMenu() {
      return this.historyRecordDateMenu = !this.historyRecordDateMenu;
    },
    closeHistoryRecordDateMenu() {
      if(this.historyRecordDateRange && this.historyRecordDateRange[0] && this.historyRecordDateRange[1]){
        this.historyRecordDateMenu = false;
      }else{
        this.historyRecordDateMenu = true;
      }
      return this.historyRecordDateMenu;
    },
    prepareDataExportHistoryRecord(asset_id, start_time, end_time) {
      const data = {
        asset_id: asset_id,
        start_time: start_time,
        end_time: end_time,
      };
      return data;
    },
    async submitHistoryRecord() {
      if (this.$refs.historyRecordForm.validate()) {
        let start_date = this.historyRecordDateRange?.length ? this.historyRecordDateRange[0] : null;
        let end_date = this.historyRecordDateRange?.length ? this.historyRecordDateRange[1] : null;
        if (this.reportRange === 'custom_range') {
          start_date = moment(start_date).startOf('day').format('YYYY-MM-DD HH:mm:ss');
          end_date = moment(end_date).endOf('day').format('YYYY-MM-DD HH:mm:ss');
        }
        const data = this.prepareDataExportHistoryRecord(
          this.dataDevice.id,
          start_date,
          end_date
        );
        await assetService
          .exportHistoryRecord(data)
          .then((res) => {
            if (res.status !== 200) throw res;
            this.closeDialogHistoryRecord();
            this.apiMessage = "History record has been successfully exported";
            this.apiTitle = "Success";
            this.messageDialog = true;
          })
          .catch((err) => {
            let message = "History record cannot be exported";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
            this.apiMessage = message;
            this.apiTitle = "Error";
            this.messageDialog = true;
          });
      }
    },
    changeInfoTabs() {
      const deviceType = this.device_type;
      const selectedTab = this.selectedInfoTabs;
      const tabMappings = {
        evse: {
          connector_1: 'connector1',
          connector_2: 'connector2'
        },
        pv: {
          pv_string_1: 'pv1',
          pv_string_2: 'pv2',
          pv_string_3: 'pv3'
        },
        bess: {
          pack_1: 'pack1',
          pack_2: 'pack2'
        },
        pm: {
          default: 'pm'
        },
        sc: {
          default: null
        },
        default: null
      };
      const tabMap = tabMappings[deviceType] || tabMappings.default;
      const mappedTab = tabMap[selectedTab] || tabMap.default;
      if (deviceType === 'sc') {
        this.moreDetailInfoData = this.deviceDetailData?.device || null;
      } else if (mappedTab !== null) {
        this.moreDetailInfoData = this.deviceDetailData?.device?.[mappedTab] || null;
      } else {
        this.moreDetailInfoData = null;
      }
    },
    async openDialogDeviceDetail(device_id) {
      if(device_id) {
        await assetService
          .getAssetDetail(device_id)
          .then((res) => {
            if (res?.data?.data) {
              const data = res.data.data;
              this.deviceDetailData = {...data};
              this.device_type = this.deviceDetailData?.device?.type;
            }
          })
          .catch();
      }
    },
    handleChangeRangeType () {
      this.historyRecordDateRange = [];
      if (this.reportRange === 'all') {
        this.historyRecordDateRange[0] = moment(this.dataDevice.created_at).startOf('day').format('YYYY-MM-DD HH:mm:ss');
        this.historyRecordDateRange[1] = moment().format('YYYY-MM-DD HH:mm:ss');
      } else if (this.reportRange === 'month_to_date') {
        this.historyRecordDateRange[0] = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');
        this.historyRecordDateRange[1] = moment().format('YYYY-MM-DD HH:mm:ss');
      } else if (this.reportRange === 'year_to_date') {
        this.historyRecordDateRange[0] = moment().startOf('year').format('YYYY-MM-DD HH:mm:ss');
        this.historyRecordDateRange[1] = moment().format('YYYY-MM-DD HH:mm:ss');
      } else if (this.reportRange === 'previous_month') {
        this.historyRecordDateRange[0] = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD HH:mm:ss');
        this.historyRecordDateRange[1] = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD HH:mm:ss');
      } else if (this.reportRange === 'previous_year') {
        this.historyRecordDateRange[0] = moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD HH:mm:ss');
        this.historyRecordDateRange[1] = moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD HH:mm:ss');
      } else if (this.reportRange === 'past_30_days') {
        this.historyRecordDateRange[0] = moment().subtract(30, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss');
        this.historyRecordDateRange[1] = moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss');
      } else {
        this.historyRecordDateRange[0] = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss');
        this.historyRecordDateRange[1] = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
      }
    },
    closeMessage() {
      this.messageDialog = false;
    },
  },
  beforeDestroy() {
    if (this.socketData) {
      this.socketData.close();
    }
  },
};
</script>
